<template>
  <div role="group" class="form-group form-row">
    <label v-if="label || array >= 1" :for="name" class="col-form-label" :class="settings.classes.label">{{ array >= 1 ? '' : $t(label) }}</label>
    <div class="row no-gutters" :class="settings.classes.input">
      <!--CInput ref="input" v-model="state" v-bind="$attrs" v-on="listeners" :class="{ 'col-sm-12': !helper, 'col-sm-6': !!helper }" /-->
      <div :class="{ 'col-sm-12': !helper, 'col-sm-5': !!helper }">
        <div class="input-group">
          <!--span class="input-group-text">{{ $t('Browse') }}</span-->
          <input ref="input" type="file" class="form-control" :class="{ 'is-invalid': !isValid }" :name="name" :placeholder="$t(cplaceholder)" v-bind="$attrs" v-on="listeners" />
          <!--div v-if="array >= 0" class="input-group-append">
            <button type="button" role="button" class="input-group-text" @click="remove(array)"><CIcon name="cil-x" /></button>
          </div-->
        </div>
      </div>
      <Label v-if="helper" class="ml-3 col-sm-5 control-helper">{{ $t(helper) }}</Label>
      <template v-if="!isValid">
        <!--div v-if="!errors.length" class="invalid-feedback">{{ $(`The :attribute field is invalid`).replace(':attribute', $t(label || '')) }}</div-->
        <div v-for="(error, index) in errors" :key="index" class="invalid-feedback" style="display: block;">{{ error.message }}</div>
      </template>
    </div>
  </div>
</template>

<script>
const CONFIG = { classes: { input: 'col-sm-9', label: 'col-sm-3' } }

export default {
  name: 'AInputFile',
  inheritAttrs: true,
  watch: {
    value(val) {
      this.state = val
    },
    multiple(val) {
      this.multiple = val
      this.parseAttrs()
    }
  },
  props: {
    value: {
      type: [String, Number]
    },
    label: {
      type: String
    },
    horizontal: {
      type: [Boolean, Object],
      default: false
    },
    helper: {
      type: String
    },
    placeholder: {
      type: String
    },
    array: {
      type: Number,
      default: -1
    },
    remove: {
      type: Function,
      default: () => false
    },
    multiple: {
      type: Boolean,
      default: false
    },

    // TODO: Use config ???

    isValid: {
      type: Boolean,
      default: true
    },
    errors: {
      type: Array
    }
  },
  computed: {
    name() {
      return this.$attrs.name || this.$attrs.id || this.$vnode.data.model.expression.split('.').pop()
    },
    name_clean() {
      return this.name.split('.')[0]
    },
    listeners() {
      const { ...listeners } = this.$listeners
      return listeners
      /*return {
        0...this.$listeners,
        input: event => this.$emit('input', event.value)
        //change: event => this.$emit('change', event),
        //update: event => this.$emit('update:value', event)
      }*/
    }
  },
  data() {
    return {
      state: this.value,
      settings: { ...CONFIG },
      cplaceholder: this.$attrs.placeholder || this.placeholder || this.label
    }
  },
  created() {
    if (typeof this.horizontal === 'object') this.settings.classes = { ...this.settings.classes, ...this.horizontal }
  },
  methods: {
    parseAttrs() {
      if (this.multiple) this.$attrs.multiple = 'multiple'
      else delete this.$attrs.multiple
    },
    resetFiles() {
      console.log(this.$refs.input)
      this.$refs.input.value = ''
      delete this.$attrs.multiple
    }
    /*onInput(e) {
      this.state = e.target.value
      this.$emit('input', this.state, e)
      /*if (this.lazy === true) {
        return
      }* /

      clearTimeout(this.syncTimeout)
      this.syncTimeout = setTimeout(
        () => {
          this.$emit('update:value', this.state, e)
        } /*,
        this.lazy !== false ? this.lazy : 0* /
      )
    }*/
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/style';

.control-helper {
  color: $input-placeholder-color;
  font-size: 0.9em;
  padding-top: 7px;
}

.invalid-feedback {
  display: block;
}

input[type='file'],
input[type='file']:visited,
input[type='file']:hover,
input[type='file']:focus,
input[type='file']:active {
  color: #768195;
  cursor: pointer;
  margin: 0;
  padding: 0;
  outline: none;
  overflow: hidden; /* long file names overflow so just hide the end */
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
}

input[type='file'] {
  &:hover {
    background: #f9f9ff;
  }

  &:visited,
  &:focus,
  &:active {
    background: #fff; /* Default back to white when focused. */
  }

  /* Note: Firefox flags the file name box as a *readonly* input. So that attribute selector was added below. Note: These selectors blow up in IE so have to be separated from the same styles above. */
  &:disabled,
  &:read-only {
    color: #acb3bd;
    cursor: default;
    margin: 0;
    padding: 0;
    outline: none;
    overflow: hidden; /* long file names overflow so just hide the end */
    background: #ffffff;
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
  }

  &:disabled:hover,
  &:read-only:hover {
    background: #f9f9ff; /* I am using a light blue to indicate an interaction */
  }

  &:disabled:visited,
  &:disabled:focus,
  &:disabled:active,
  &:read-only:visited,
  &:read-only:focus,
  &:read-only:active {
    background: #fff; /* Default back to white when focused. */
  }
}

/* IE: This attempts to alter the file upload button style in IE.  Keep in mind IE gives you limited design control but at least you can customize its upload button.*/
::-ms-browse {
  display: inline-block;
  margin: 0;
  padding: 0.2em 0.5em;
  padding: 0.2rem 0.5rem;
  text-align: center;
  outline: none;
  border: none;
  background: #fff;
  white-space: nowrap;
  cursor: pointer;
}
/* Firefox */
::file-selector-button {
  color: #5c6873;
  height: 100%;
  display: inline-block;
  margin: 0rem 1rem 0rem 0rem;
  padding: 0.18em 0.5em;
  padding: 0.18rem 0.5rem;
  -webkit-appearance: button;
  text-align: center;
  border-radius: 0.1rem 0rem 0rem 0.1rem;
  outline: none;
  border: none;
  border-right: 1px solid #e4e7ea;
  background: #f0f3f5;
  white-space: nowrap;
  cursor: pointer;
}
/* Chrome & Edge */
::-webkit-file-upload-button {
  color: #5c6873;
  height: 100%;
  display: inline-block;
  margin: 0rem 1rem 0rem 0rem;
  padding: 0.19em 0.5em;
  padding: 0.19rem 0.5rem;
  -webkit-appearance: button;
  text-align: center;
  border-radius: 0.1rem 0rem 0rem 0.1rem;
  outline: none;
  border: none;
  border-right: 1px solid #e4e7ea;
  background: #f0f3f5;
  white-space: nowrap;
  cursor: pointer;
}
</style>
